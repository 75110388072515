<template>
  <div class="vld-parent cyb-login base-page">
    <login-form :hasForm="false" pageclass="darker">
      <template #text-content>
        <div class="p-d-flex p-flex-column p-ai-start p-jc-center page-width p-d-flex-1">
          <div class="content">
            <div class="base-template">
              <h1><span>{{ $t('networkNotAvailable.title') }}</span></h1>
              <p>{{ $t('networkNotAvailable.details') }}</p>
              <br />
              <Button
                :label="$t('networkNotAvailable.retryButton')"
                class="p-button p-button-sm p-pl-6 p-pr-6" @click="retryConnection()"></Button>
            </div>
          </div>
        </div>
      </template>
    </login-form>
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
export default {
  data() {
    return {
    };
  },
  methods: {
      retryConnection() {
          window.location.href = window.location.origin;
      }
  },
  components: {
      LoginForm
  }
};
</script>